<template>
    <div class="operation">
        <!--  -->
        <div class="head padd20 borbut20">

            <div class="topSearchSou">
                <div class="searchdan">
                    <div class="title">运营官：</div>
                    <el-input v-model="input" placeholder="请输入运营官名称"></el-input>
                </div>
                <el-button type="primary" icon="el-icon-search" @click="onButtonInquire()">搜索</el-button>
                <el-button class="Shuarefresh" @click="$router.go(0)" icon='el-icon-refresh-right'>重置数据</el-button>
            </div>
            <div></div>
        </div>
        <!--  -->
        <div class="tab padd20">
            <div class="duoshao">
                <div class="title">运营官列表</div>
                <el-button type="primary" id="Operate" @click="addyunying($event)" class="yinyinga" icon="el-icon-plus">新增运营官</el-button>
                <div class="lvtotal">总条数：{{lvtotal}} 条</div>
            </div>
            <el-table ref="filterTable" :data="tableData" style="width: 100%">
                <el-table-column prop="id" label="ID">
                </el-table-column>
                <el-table-column prop="operating_name" label="运营人员名称">
                </el-table-column>
                <el-table-column prop="operating_mobile" label="运营人员手机号">
                </el-table-column>
                <el-table-column prop="created_at" label="创建时间">
                </el-table-column>
                <el-table-column prop="updated_at" label="更新时间">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" class="bianji" size="mini" id="CompileRun" @click="handleEdit(scope.$index, scope.row,$event)">编辑</el-button>
                        <!-- <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <div class="fenye">
                <el-pagination class="lv-page" :page-size="per_page" :pager-count="11" @current-change="DialogCurrentChange" background layout="prev, pager, next" :total="lvtotal" hide-on-single-page :current-page.sync="paramPage"></el-pagination>
            </div>
        </div>

        <!-- 弹窗 -->
        <div>
            <Operate ref="Operate" :msg="activeName" @yunying="yunying" @bianji="bianji" v-if="activeName == 'Operate'"></Operate>
            <CompileRun ref="CompileRun" :id="operating_id" :msg="activeName" @bianji="bianji" @yunying="yunying" v-if="activeName == 'CompileRun'"></CompileRun>
        </div>
    </div>
</template>

<script>
import Operate from "./Operate.vue";
import CompileRun from "./CompileRun.vue";
export default {
    data() {
        return {
            paramData:{},//表格参数
            input: "", //搜索内容
            operating_id: "", //运营官id
            tableData: [], //显示运营列表
            per_page: 10, //一页多少条
            lvtotal: null, //共几条数据
            paramPage:1,//表格当前第几页
            page: "1",
            activeName: "",
        };
    },
    mounted() {
        this.ongetTopOperatingList();
    },
    components: {
        Operate,
        CompileRun,
    },
    methods: {
        //运营官搜索触发
        onButtonInquire(){
            this.paramData = {
                operating_name: this.input,
            }
            this.paramPage = 1
            this.ongetTopOperatingList()
        },
        //运营官列表
        ongetTopOperatingList() {
            var param = this.paramData
            param.page = this.paramPage
            this.$service.get(this.$api.getTopOperatingList, param, (res) => {
                if (res.code == "200") {
                    this.tableData = res.data.data;
                    this.lvtotal = res.data.total;
                }
            });
        },
        // 分页
        DialogCurrentChange(val) {
            this.paramPage = val
            this.ongetTopOperatingList()
        },
        // 筛选
        filterTag(value, row) {
            return row.tag === value;
        },
        // 编辑
        handleEdit(index, row, e) {
            this.activeName = e.currentTarget.id;
            this.operating_id = row.id;
            // console.log(e.srcElement.id);
            // console.log(index, row);
        },
        // 删除
        handleDelete(index, row) {
            // console.log(index, row);
        },
        //新增运营官
        addyunying(e) {
            this.activeName = e.currentTarget.id;
        },
        //运营组件
        yunying() {
            this.activeName = "";
        },
        //编辑组件
        bianji() {
            // console.log(data)
            this.getTopOperatingList();
            this.activeName = "";
        },
    },
};
</script>

<style lang="scss" scoped>
.operation {
    display: flex;
    flex-direction: column; //垂直显示
    .head {
        display: flex;
        justify-content: center;
    }

    .tab {
        // display: flex;
    }
    .fenye {
        text-align: right;
        margin-top: 25px;
        margin-right: 50px;
    }
    .xzyunying {
        margin-bottom: 20px;
    }
}
</style>
<style lang="scss">
.operation {
    .el-input {
        width: 300px;
    }
    .el-step__title {
        margin-left: -5px;
    }

    .el-table__column-filter-trigger i {
        color: #0075f7;
        margin-left: 10px;
    }
    .el-form-item__label {
        text-align: left;
    }
    .el-form-item {
        margin-top: 20px;
    }
    .el-form-item__content {
        margin-left: 0 !important;
    }
    .dialog-footer {
        display: flex;
        justify-content: center;
    }
    .el-dialog__header {
        padding: 20px 20px;
    }
    // .el-dialog{
    //     width: 40%;
    // }
}
</style>