<template>
    <div class="Operate">
        <!-- 表单弹窗 -->
        <div class="from">
            <el-dialog title="配置运营官" :visible.sync="yunyingtan" width="700px" :closeOnClickModal='false' @close="closeDialog()">
                <div class="chuangjian"></div>
                <el-form :model="form">
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            运营名称
                        </div>
                        <el-input v-model="form.operating_name" autocomplete="off" placeholder="请输入运营名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            运营联系电话
                        </div>
                        <el-input v-model="form.operating_mobile" autocomplete="off" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            选择企业:
                        </div>
                        <el-select v-model="form.corporate_account_id" placeholder="请选择企业" popper-class='flock-select'>
                            <div v-infinite-scroll="load" style="" class="flock-load">
                                <el-option :label="item.corporate_account_name" :value="item.id" v-for='(item,index) in inputD' :key="index"></el-option>
                            </div>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="" :label-width="formLabelWidth">
                        <div class="kuai">
                            <span class="xing">* </span>
                            头像
                        </div>
                        <el-upload class="upload-demo" drag :action="$api.upload + '?token=' + '7ce563cc4f49544991de6ed0ac743bbe'" :limit="2" ref="upload" list-type="picture" accept=".jpeg,.jpg,.png,.gif," show-file-list :file-list="fileListUpload" :on-remove="onRemoveupload" :on-success="onSuccessupload" :on-change="onChangeUpload">
                            <i class="el-icon-upload"></i>
                            <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                            <div class="el-upload__tip" slot="tip">最多可传 1 个，扩展名为：.jpeg,.jpg,.png,.gif,</div>
                        </el-upload>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="quxiao" class="success button">取 消</el-button>
                    <el-button type="primary" @click="tijiao" class="tijiao primary button">提 交</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
export default {
    props: ["msg"],
    data() {
        return {
            yunyingtan: true, //操控弹窗展示
            form: {
                //表单信息
                operating_name: "",
                operating_mobile: "",
                corporate_account_name: "",
            },
            formLabelWidth: "140px",
            activeName: "", //显示组件
            imageUrl: "", //头像
            fileListUpload: [], //上传的文件列表
            page: 1,
            inputD: "",
        };
    },
    mounted() {
        // this.getTopCorporateAccountList();
        if (this.msg == Operate) {
            this.activeName == true;
        }
    },
    methods: {
        // 获取企业信息
        getTopCorporateAccountList() {
            var param = {
                page: this.page,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    if (res.code == "200") {
                        this.inputD = res.data.data;
                        // console.log(this.inputD);
                    }
                }
            );
        },
        //选择企业触底事件
        load() {
            this.page += 1;
            var param = {
                page: this.page,
            };
            this.$service.get(
                this.$api.getTopCorporateAccountList,
                param,
                (res) => {
                    var data = res.data.data;
                    if (data == "") {
                        this.$message({
                            message: "已没有更多数据",
                            type: "warning",
                        });
                        return;
                    }
                    if (res.code == "200") {
                        var inputD = this.inputD;
                        data.forEach(function (item, index) {
                            inputD.push(item);
                        });
                        this.inputD = inputD;
                    }
                }
            );
        },
        quxiao() {
            this.$emit("yunying");
            this.yunyingtan = false;
        },
        closeDialog() {
            this.$emit("yunying");
            this.yunyingtan = false;
        },
        tijiao() {
            var param = this.form;
            delete param.corporate_account_name;
            if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(param.operating_mobile)) {
                this.$message({ message: "请正确输入手机号", type: "warning" });
            } else {
                this.$service.post(
                    this.$api.createTopOperating,
                    param,
                    (res) => {
                        if (res.code == "200") {
                            this.$message({
                                message: "新增成功",
                                type: "success",
                            });
                            this.$emit("bianji");
                            this.yunyingtan = false;
                            // console.log(res);
                        }
                    }
                );
            }
        },

        //上传文件移出
        onRemoveupload(file, fileList) {
            this.fileListUpload = fileList;
            this.file_id = "";
            this.$message({
                message: "文件移出成功，请重新上传文件",
                type: "success",
            });
        },
        //文件上传成功
        onSuccessupload(res, file, fileList) {
            // console.log(1111);
            if (fileList.length > 1) {
                fileList.splice(0, 1);
            }
            if (res.code == 200) {
                this.file_id = res.data.id;
                this.form.file_id = file.response.data.id;
                this.form.upload_img = file.response.data.file_url;
            }
        },
        //文件上传成功，失败 所有都会调用
        onChangeUpload(file, fileList) {
            // console.log(file, "文件上传成功，失败 所有都会调用");
        },
    },
};
</script>

<style lang="scss" scoped>
.Operate {
    .chuangjian {
        // height: 40px;
        line-height: 40px;
        font-weight: bold;
    }
    .xing {
        color: red;
        margin-right: 6px;
    }
    .kuai {
        width: 140px;
        display: inline-block;
    }
    .tijiao {
        margin-left: 40px;
    }
    // .button {
    //     width: 90px;
    //     color: #ffffff;
    // }
    //
}
</style>
<style lang="scss">
.Operate {
    .upload-demo {
        width: 200px;
        display: inline-block;
    }
    .el-upload-dragger {
        height: 150px;
    }
    .el-upload__tip {
        width: 250px;
    }
    .el-dialog__header {
        background: #0075f7;
    }
    .el-dialog__title {
        color: #ffffff;
    }
}
</style>